@@ -0,0 +1,334 @@
<template>
 <div v-if="userProfiling">
    <v-form ref="userProfileForm" v-model="valid" lazy-validation>
      <v-text-field
        v-model="name"
        :rules="[(v) => !!v || 'Name is required']"
        outlined
        label="Name"
        required
      ></v-text-field>

      <v-select
        v-model="userProfiling.status"
        :items="statusItems"
        item-text="text"
        item-value="value"
        label="Status"
        outlined
        required
      ></v-select>

      <v-text-field
        v-model="description"
        outlined
        label="Description"
        required
      ></v-text-field>

      <v-text-field
        v-model="userProfiling.points"
        outlined
        label="Points"
        required
        type="number"
        :rules="pointsRules"
      ></v-text-field>

      <v-text-field
        v-model="userProfiling.surveyUrl"
        outlined
        label="Survey Url"
        :rules="[(v) => !!v || 'Survey Url is required']"
        required
      ></v-text-field>

      <v-select
        v-model="userProfiling.loi"
        :items="loiItems"
        item-text="text"
        item-value="value"
        :rules="[(v) => !!v || 'Length Of Interview is required']"
        label="Length Of Interview"
        outlined
        required
      ></v-select>

      <v-select
        v-model="userProfiling.activityType"
        :items="activityTypeItems"
        :rules="[(v) => !!v || 'activty Type is required']"
        label="activty Type"
        outlined
        required
      ></v-select>
    </v-form>
    <v-btn class="mr-3" text @click="$router.push({ name: 'MobileUserProfileIndex' })">
      back
    </v-btn>
    <v-btn color="primary" @click="editUserProfile">
      Edit User Profile
    </v-btn>
  </div>
</template>
<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();
export default {
  mounted() {
      this.getUserProfiling()
  },
  data(){
    return {
      userProfiling:{},
      valid: false,
      activityTypeItems: ["fun", "insight", "more", "other"],
      loiItems: [
        {
          text: "1 minute",
          value: 1
        },
        {
          text: "2 minutes",
          value: 2
        },
        {
          text: "3 minutes",
          value: 3
        },
        {
          text: "5 minutes",
          value: 5
        },
        {
          text: "10 minutes",
          value: 10
        },
        {
          text: "12 minutes",
          value: 12
        },
        {
          text: "15 minutes",
          value: 15
        },
        {
          text: "20 minutes",
          value: 20
        },
        {
          text: "30 minutes",
          value: 30
        }
      ],
      pointsRules: [
        (v) => !!v || "Points is required",
        (v) => (v && v > 0) || "Points must be greater than 0",
        (v) => (v && v <= 100) || "Points must be less than or equal to 100",
      ],
      statusItems:[{text:"Active",value:"A"},{text:"Inactive",value:"I"}]
    }
  },
  methods: {
    getUserProfiling() {
      this.$setLoader()
      let data = {
        userProfilingId: this.$route.params.userProfilingId
      }
      service.getUserProfiling(data).then(r => {
          this.userProfiling = r.data
          this.$disableLoader()
      })
    },
    editUserProfile(){
      let isValid = this.$refs.userProfileForm.validate();
      if (isValid) {
        this.$setLoader()
        let data = {
          userProfilingId: this.$route.params.userProfilingId,
          name: this.name,
          description: this.description,
          points: this.userProfiling.points,
          surveyUrl: this.userProfiling.surveyUrl,
          activityType: this.userProfiling.activityType,
          status: this.userProfiling.status
        }
        service.updateUserProfile(data).then(() => {
          this.$disableLoader()
          this.$router.push({ name: "MobileUserProfileIndex" });
        })
      }
    }
  },
  computed:{
    name:{
      get(){
        if(this.userProfiling.name && this.userProfiling.name.en)
          return this.userProfiling.name.en

        return this.userProfiling.name
      },
      set(value){
        if(this.userProfiling.name && this.userProfiling.name.en){
          this.userProfiling.name.en = value
          return
        }
        this.userProfiling.name = value
      }
    },
    description:{
      get(){
        if(this.userProfiling.description && this.userProfiling.description.en)
          return this.userProfiling.description.en

        return this.userProfiling.description
      },
      set(value){
        if(this.userProfiling.description && this.userProfiling.description.en){
          this.userProfiling.description.en = value
          return
        }
        this.userProfiling.description = value
      }
    },
  }
}
</script>